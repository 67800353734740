import React, { useState } from 'react';
import './LoginScreen.css';  // Make sure this path is correct
import dashboardImage from '../assets/dashboard.png'; // Make sure to add this image to your assets folder
import logoImage from '../assets/logo.png'; // Add this line and ensure the path is correct

function LoginScreen() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Login attempt with:', { email, password });
  };

  return (
    <div className="login-container">
      <div className="login-form-container">
        <div className="login-form">
          <img src={logoImage} alt="Exti Logo" className="logo" />
          <h1>Logga in till <br></br>Exti</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Login</button>
          </form>
          <div className="login-links">
            <a href="#forgot-password">Forgot your password?</a>
            <a href="#sign-up">Not a subscriber yet? Sign up</a>
          </div>
        </div>
      </div>
      <div className="login-image">
        <img src={dashboardImage} alt="Dashboard preview" />
      </div>
    </div>
  );
}

export default LoginScreen;  // This should be at the bottom of the file
