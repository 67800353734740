import React from 'react';
import './App.css';
import LoginScreen from './components/LoginScreen';  // Use default import

function App() {
  return (
    <div className="App">
      <LoginScreen />
    </div>
  );
}

export default App;
